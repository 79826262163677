<template>
  <!-- <div class="container page-section"> -->
  <div class="container col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-5">
    <ValidationObserver ref="validate">
      <form-wizard
        @on-complete="save"
        shape="circle"
        color="#2ecc71"
        error-color="#ff4949"
        :title="$t('form.cadastro-estabelecimento')"
        subtitle=""
      >
        <!-- color="#20a0ff" -->
        <!-- Tab estabelecimento -->
        <tab-content
          :title="$t('geral.labels.estabelecimento')"
          icon="ti-shopping-cart"
          :before-change="validForm1"
        >
          <ValidationObserver ref="valForm1">
            <b-card>
              <b-form-group
                id="input-group-1"
                :label="$t('form.nome-estabelecimento')"
                label-for="input-1"
                class="col-md-12 col-sm-12"
              >
                <ValidationProvider
                  name="nome"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.nome"
                    :placeholder="$t('geral.placeholders.form-estabelecimento')"
                  ></b-form-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>

              <validation-provider
                name="CNPJ"
                rules="cnpj"
                v-slot="validationContext"
              >
                <b-form-group
                  id="cnpj-input-group"
                  :label="$t('form.cnpj')"
                  label-for="cnpj-input"
                  class="col-md-12 col-sm-12"
                  :invalid-feedback="validationContext.errors[0]"
                  :state="getValidationState(validationContext)"
                  description="(Opcional)"
                >
                  <b-form-input
                    id="cnpj-input"
                    name="cnp-input"
                    v-model="form.cnpj"
                    v-mask="['##.###.###/####-##']"
                  ></b-form-input>
                </b-form-group>
              </validation-provider>
              <b-form-group
                id="input-group-8"
                :label="$t('geral.labels.categoria-estabelecimento')"
                label-for="input-8"
                class="col-md-12 col-sm-12"
              >
                <ValidationProvider
                  name="tipo do estabelecimento"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <multiselect
                    select-label="Selecionar"
                    deselect-label="Remover"
                    selected-label="Selecionado"
                    v-model="form.tiposEstabelecimento"
                    :options="tipos"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="
                      $t('geral.placeholders.form-categoria-estabelecimento')
                    "
                    label="nome"
                    track-by="id"
                    :preselect-first="true"
                  >
                    <span slot="noResult">{{
                      $t("geral.mensagens.nenhum-tipo-estabalecimento")
                    }}</span>
                    <span slot="noOptions">{{
                      $t("geral.mensagens.nenhum-tipo-estabalecimento")
                    }}</span>
                  </multiselect>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                :label="$t('geral.labels.dias-funcionamento')"
                class="col-md-12 col-sm-12"
              >
                <ValidationProvider
                  name="dias"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-checkbox-group
                    v-model="form.diasFuncionamento"
                    id="checkboxes-4"
                    :options="dias"
                    text-field="label"
                    value-field="value"
                    stacked
                  ></b-form-checkbox-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                id="input-group-9"
                :label="$t('form.horario-funcionamento')"
                label-for="input-9"
                class="col-md-12 col-sm-12"
              >
                <ValidationProvider
                  name="horarios"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    id="input-9"
                    v-model="form.horarioFuncionamento"
                    :placeholder="
                      $t('geral.placeholders.form-horario-funcionamento')
                    "
                    rows="3"
                    max-rows="6"
                    style="max-height: 100px; min-height: 50px; overflow-y: hidden"
                  ></b-form-textarea>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>

              <!-- Delivery? -->
              <b-row class="col-md-12">
                <b-form-group
                  id="input-group-11"
                  :label="$t('form.realiza-delivery')"
                  label-for="input-11"
                  class="col-lg-6 col-md-6 col-sm-12 inline"
                >
                  <ValidationProvider
                    name="retirada"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-radio-group
                      id="btn-radios-1"
                      v-model="form.isDelivery"
                      :options="confirmOptions"
                      buttons
                      button-variant="outline-success"
                      size="md"
                      name="radio-btn-outline"
                      class="mr-1"
                    ></b-form-radio-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <money
                    class="col-lg-6 col-md-6 col-sm-12"
                    v-show="form.isDelivery"
                    v-model="form.valorDelivery"
                    placeholder="Valor delivery"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-12"
                  :label="$t('form.permite-retirada')"
                  label-for="btn-radios-1"
                  class="col-md-6 col-sm-12"
                >
                  <ValidationProvider
                    name="retirada"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-radio-group
                      id="btn-radios-1"
                      v-model="form.isPermiteRetirada"
                      :options="confirmOptions"
                      buttons
                      button-variant="outline-success"
                      size="md"
                      name="radio-btn-outline"
                    ></b-form-radio-group>
                    <br />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-row>

              <b-form-group
                id="input-group-12"
                :label="$t('form.tempo-medio-espera')"
                label-for="input-12"
                class="col-md-12 col-sm-12"
                description="(opcional)"
              >
                <b-form-input
                  id="input-12"
                  v-model="form.tempoEspera"
                  type="number"
                  v-mask="'###'"
                  :placeholder="
                    $t('geral.placeholders.form-tempo-medio-espera')
                  "
                  :disabled="!form.isDelivery"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-9"
                :label="$t('form.sobre-negocio')"
                label-for="input-9"
                class="col-md-12 col-sm-12"
                :description="
                  `Restam ${300 - form.descricao.length} caracteres`
                "
              >
                <ValidationProvider
                  name="descricao"
                  :rules="showDescription ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    id="input-9"
                    v-model="form.descricao"
                    :placeholder="$t('geral.placeholders.form-sobre-negocio')"
                    max-rows="3"
                    maxlength="300"
                    style="max-height: 150px; min-height: 100px; overflow-y: hidden"
                  ></b-form-textarea>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-card>
          </ValidationObserver>
        </tab-content>

        <!-- Tab endereço -->
        <tab-content
          :title="$t('geral.labels.endereco')"
          icon="ti-location-pin"
          :before-change="validForm2"
        >
          <ValidationObserver ref="valForm2">
            <b-card>
              <!-- CEP -->
              <!-- <b-form-group label="CEP" class="col-md-6 col-sm-12">
                <ValidationProvider name="cep" rules="" v-slot="{ errors }">
                  <b-form-input
                    v-model="form.enderecoObj.cep"
                    placeholder="00000-000"
                    v-mask="'#####-###'"
                    @change="buscaCep(form.enderecoObj.cep)"
                  >
                  </b-form-input>
                  <span
                    ><a href="https://achacep.com.br" target="_blank"
                      >Não sei meu CEP</a
                    ></span
                  >
                  <span class="text-danger"><br />{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group> -->

              <!-- Estado -->
              <b-row class="col-md-12">
                <b-form-group
                  id="input-group-7"
                  :label="$t('geral.labels.estado')"
                  label-for="input-7"
                  class="col-lg-6 col-md-6 col-sm-12"
                >
                  <ValidationProvider
                    name="estado"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      select-label="Selecionar"
                      deselect-label="Remover"
                      selected-label="Selecionado"
                      v-model="form.estado"
                      :options="estados"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      :placeholder="$t('geral.placeholders.selecione-estado')"
                      label="name"
                      @input="getCidades(form.estado)"
                    >
                      <span slot="noResult">{{
                        $t("geral.mensagens.nenhum-estado")
                      }}</span>
                      <span slot="noOptions">{{
                        $t("geral.mensagens.nenhum-estado")
                      }}</span>
                    </multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <!-- Cidade -->
                <b-form-group
                  id="input-group-7"
                  :label="$t('geral.labels.cidade')"
                  label-for="input-7"
                  class="col-lg-6 col-md-6 col-sm-12"
                >
                  <ValidationProvider
                    name="cidade"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      select-label="Selecionar"
                      deselect-label="Remover"
                      selected-label="Selecionado"
                      v-model="form.cidadeObj"
                      :options="cidades"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      :searchable="true"
                      :placeholder="$t('geral.placeholders.selecione-cidade')"
                      label="nome"
                    >
                      <span slot="noResult">{{
                        $t("geral.mensagens.nenhuma-cidade")
                      }}</span>
                      <span slot="noOptions">{{
                        $t("geral.mensagens.nenhuma-cidade")
                      }}</span>
                    </multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-row>

              <!-- BAIRRO -->
              <b-row class="col-md-12">
                <b-form-group
                  :label="$t('geral.labels.bairro')"
                  class="col-md-12 col-sm-12"
                >
                  <ValidationProvider
                    name="bairro"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.enderecoObj.bairro"
                      :placeholder="$t('geral.placeholders.form-bairro')"
                    >
                    </b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-row>

              <!-- RUA -->
              <b-row class="col-md-12">
                <b-form-group
                  :label="$t('form.rua')"
                  class="col-lg-8 col-md-7 col-sm-8"
                >
                  <ValidationProvider
                    name="rua"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.enderecoObj.rua"
                      :placeholder="$t('geral.placeholders.form-rua')"
                    >
                    </b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <!-- NUMERO -->
                <b-form-group
                  :label="$t('form.numero')"
                  class="col-lg-4 col-md-5 col-sm-4"
                >
                  <ValidationProvider
                    name="numero"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.enderecoObj.numero"
                      :placeholder="$t('geral.placeholders.form-numero')"
                    >
                      <!-- placeholder="00a" -->
                    </b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-row>

              <!-- REFERENCIA -->
              <b-row class="col-md-12">
                <b-form-group
                  :label="$t('form.complemento')"
                  class="col-md-12 col-sm-12"
                >
                  <ValidationProvider
                    name="referencia"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.enderecoObj.referencia"
                      :placeholder="$t('geral.placeholders.form-complemento')"
                    >
                    </b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-card>
          </ValidationObserver>
        </tab-content>

        <!-- Tab3 contatos -->
        <tab-content
          :title="$t('geral.labels.contatos')"
          icon="ti-email"
          :before-change="validForm3"
        >
          <ValidationObserver ref="valForm3">
            <b-card>
              <!-- TELEFONE -->
              <b-row class="col-md-12">
                <b-form-group
                  id="input-group-4"
                  :label="$t('form.telefone-contato')"
                  label-for="input-4"
                  class="col-12 col-lg-10 col-md-12 col-sm-12"
                >
                  <b-row
                    class="d-flex justify-content-between"
                    v-for="(item, index) in telefones"
                    :key="index"
                  >
                    <b-col cols="12" class="mb-3 d-flex">
                      <ValidationProvider
                        class="flex-grow-1"
                        :name="`Telefone ${index + 1}`"
                        rules="required|min:11"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          :id="`telefone-${index + 1}`"
                          v-model="telefones[index].numero"
                          v-mask="['(##) ####-####', '(##) #####-####']"
                          :placeholder="$t('geral.placeholders.form-telefone')"
                        ></b-form-input>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div
                        class="d-flex justify-content-center align-items-center ml-1"
                        style="background: #e9ecef; padding: 5px; border: 0.5px solid #ced4da; border-radius: 0.25rem; height: 40px;"
                      >
                        <b-form-checkbox
                          v-model="telefones[index].whatsapp"
                          class="ml-2 align-self-start"
                          switch
                        >
                          <unicon
                            name="whatsapp"
                            :fill="
                              telefones[index].whatsapp ? 'green' : '#6c757d66'
                            "
                          />
                        </b-form-checkbox>
                      </div>
                      <b-button
                        class="ml-1"
                        variant="outline-secondary"
                        @click="removerTelefone(index)"
                        v-show="!(index === 0 && telefones.length === 1)"
                        style="height: 40px;"
                      >
                        <b-icon-trash />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-button
                    variant="success"
                    block
                    @click="adicionarTelefone"
                    v-show="telefones.length < 3"
                  >
                    Adicionar Telefone
                  </b-button>
                </b-form-group>
              </b-row>

              <!-- Email -->
              <b-row class="col-md-12">
                <b-form-group
                  :label="$t('geral.labels.email')"
                  class="col-md-12 col-sm-12 email-validation"
                  :invalid-feedback="'Insira um email válido!'"
                  :state="state()"
                  description="(opcional)"
                >
                  <b-form-input
                    v-model="form.email"
                    :placeholder="$t('geral.placeholders.form-email')"
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-row>

              <!-- Instagram -->
              <b-row class="col-md-12">
                <b-form-group
                  id="input-group-13"
                  :label="$t('geral.labels.instagram')"
                  label-for="input-13"
                  class="col-md-12 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    id="input-13"
                    v-model="form.instagram"
                    :placeholder="$t('geral.placeholders.form-instagram')"
                  ></b-form-input>
                </b-form-group>
              </b-row>

              <!-- Facebook -->
              <b-row class="col-md-12">
                <b-form-group
                  :label="$t('geral.labels.facebook')"
                  class="col-md-12 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    v-model="form.facebook"
                    :placeholder="$t('geral.placeholders.form-facebook')"
                  ></b-form-input>
                </b-form-group>
              </b-row>

              <!-- Site -->
              <b-row class="col-md-12">
                <b-form-group
                  :label="$t('geral.labels.site')"
                  class="col-md-12 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    v-model="form.site"
                    :placeholder="$t('geral.placeholders.form-site')"
                  ></b-form-input>
                </b-form-group>
              </b-row>

              <!-- Ifood -
              <b-row class="col-md-12">
                <b-form-group
                  :label="$t('geral.labels.ifood')"
                  class="col-md-12 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    v-model="form.ifood"
                    :placeholder="$t('geral.placeholders.form-ifood')"
                  ></b-form-input>
                </b-form-group>
              </b-row>
              -->
            </b-card>
          </ValidationObserver>
        </tab-content>

        <!-- Outros -->
        <tab-content :title="$t('geral.labels.termos')" icon="ti-pin-alt">
          <b-card>
            <b-row class="col-md-12">
              <b-form-group
                id="input-group-14"
                :label="$t('form.recomendacao')"
                label-for="input-14"
                class="col-md-12 col-sm-12 check-termos"
              >
                <p class="termos text-justify">
                  {{ $t("form.message1") }}

                  <a
                    href="https://mpt.mp.br/pgt/noticias/vrecomendacao-estado-de-sp-conafret-assinada-pdf-pdf.pdf"
                    target="_blank"
                    >link</a
                  >.
                </p>
                <b-form-checkbox
                  v-model="form.aceitaRecomendacoes"
                  name="check-button"
                  switch
                  variant="success"
                >
                  {{ $t("form.message2") }}
                </b-form-checkbox>
              </b-form-group>
            </b-row>

            <b-row class="col-md-12">
              <b-form-group
                id="input-group-14"
                :label="$t('form.termos-uso')"
                label-for="input-14"
                class="col-md-12 col-sm-12 check-termos"
              >
                <p class="termos text-justify">
                  {{ $t("form.message3") }}
                </p>
                <b>
                  {{ $t("form.message4") }}
                </b>
              </b-form-group>
            </b-row>
          </b-card>
        </tab-content>

        <b-button disabled v-if="avancar_disabled" size="md" slot="prev">
          {{ $t("geral.buttons.voltar") }}
        </b-button>

        <b-button v-else size="md" variant="primary" slot="prev">
          {{ $t("geral.buttons.voltar") }}
        </b-button>

        <b-button disabled v-if="avancar_disabled" size="md" slot="next">
          {{ $t("geral.buttons.avancar") }}
        </b-button>

        <b-button v-else size="md" variant="primary" slot="next">
          {{ $t("geral.buttons.avancar") }}
        </b-button>

        <b-button v-if="!isSaving" size="md" variant="success" slot="finish">
          {{ $t("geral.buttons.salvar") }}
        </b-button>
        <b-button v-else size="md" variant="success" slot="finish" disabled>
          <b-spinner style="width: 25px; height: 25px;"></b-spinner>
          Salvando...
        </b-button>
      </form-wizard>
    </ValidationObserver>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Service from "../services/ApiServices";
import { localize } from "vee-validate";
import rEmail from "../utils/rEmail";

localize({
  pt_BR: {
    messages: {
      required: "Campo obrigatório"
    },
    fields: {
      descricao: {
        required:
          "Ao selecionar o tipo do estabelecimento apenas como 'outros' é necessário uma descrição"
      }
    }
  }
});

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      avancar_disabled: false,
      isSaving: false,
      telefone1: "",
      telefone2: "",
      whatsapp: "",
      telefones: [
        {
          numero: "",
          whatsapp: false
        }
      ],
      showDescription: false,
      tipos: [],
      estados: [],
      cidades: [],
      form: {
        descricao: "",
        aceitaTermos: true,
        aceitaRecomendacoes: false,
        horarioFuncionamento: "",
        isAprovada: true,
        nome: "",
        endereco: "",
        enderecoObj: {
          cep: "",
          bairro: "",
          rua: "",
          numero: "",
          referencia: ""
        },
        facebook: "",
        site: "",
        instagram: "",
        email: "",
        //ifood: "",
        cnpj: "",
        estado: undefined,
        cidade: undefined,
        cidadeObj: undefined,
        valorDelivery: "",
        tempoEspera: "",
        tiposEstabelecimento: undefined,
        diasFuncionamento: [],
        isDelivery: true,
        isPermiteRetirada: undefined
      },
      confirmOptions: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      dias: [
        { label: "Domingo", value: "DOMINGO" },
        { label: "Segunda-feira", value: "SEGUNDA_FEIRA" },
        { label: "Terça-feira", value: "TERCA_FEIRA" },
        { label: "Quarta-feira", value: "QUARTA_FEIRA" },
        { label: "Quinta-feira", value: "QUINTA_FEIRA" },
        { label: "Sexta-feira", value: "SEXTA_FEIRA" },
        { label: "Sábado", value: "SABADO" }
      ],
      show: true
    };
  },

  watch: {
    "form.tiposEstabelecimento"() {
      try {
        const isValid =
          this.form.tiposEstabelecimento.length == 1 &&
          this.form.tiposEstabelecimento[0].nome == "Outros";

        this.showDescription = isValid ? true : false;
      } catch (error) {
        this.showDescription = false;
      }
    },
    "form.isPermiteRetirada"(value) {
      if (!value) {
        this.form.isDelivery = true;
      }
    },
    "form.isDelivery"(value) {
      if (!value) {
        this.form.valorDelivery = 0;
        this.form.isPermiteRetirada = true;
      }
    },
    "form.estado"() {
      this.form.cidade = "";
      this.cidades = [];
      this.$refs.validate.reset();
    }
  },

  async mounted() {
    const request = new Service();
    const resp = await request.getAll(
      { sort: "nome", size: 50 },
      "tiposEstabelecimento"
    );
    if (resp) {
      this.tipos = resp.content;
    }
    this.getEstados();
  },

  methods: {
    state() {
      let valid = this.form.email == "" || this.IsEmail(this.form.email);
      this.avancar_disabled = !valid;
      return valid;
    },
    IsEmail(email) {
      if (email != "" && rEmail.test(email)) return true;
      return false;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    isOthers() {},
    removerTelefone(index) {
      this.telefones.splice(index, 1);
    },
    adicionarTelefone() {
      if (this.telefones.length <= 2)
        this.telefones.push({ numero: "", whatsapp: false });
    },

    // Validações do step form
    async validForm1() {
      return await this.$refs.valForm1.validate();
    },
    async validForm2() {
      return await this.$refs.valForm2.validate();
    },
    async validForm3() {
      return await this.$refs.valForm3.validate();
    },

    async save() {
      const valid = await this.$refs.validate.validate();
      if (!valid) {
        this.$notify({
          group: "toast",
          type: "warn",
          title: "Cadastro",
          text: "Preencha os campos obrigatórios"
        });
        return;
      }
      this.isSaving = true;
      let obj = Object.assign({}, this.form);
      obj.telefones = this.telefones;
      obj.cidade = obj.cidadeObj.nome;
      const request = new Service();
      await request
        .create(obj, "empresas")
        .then(() => {
          this.$notify({
            group: "toast",
            title: "Cadastro",
            text: "Cadastro realizado com sucesso!"
          });
          this.isSaving = false;
          this.$router.push("/");
        })
        .catch(() => {
          this.$notify({
            group: "toast",
            title: "Cadastro",
            text:
              "Houve um erro no cadastro, realize novamente ou contate o suporte!"
          });
        });
    },

    async getEstados() {
      const request = new Service();
      const resp = await request.getAll({ sort: "nome", size: 27 }, "estados");
      if (resp) {
        this.estados = resp.content;
      }
    },

    async getCidades(estado) {
      const request = new Service();
      const resp = await request.getAll(
        { sort: "nome", nomeEstado: estado.name },
        "cidades/findByEstado"
      );
      if (resp) {
        this.cidades = resp.content;
      }
    },

    async buscaCep(cep = "") {
      if (cep !== "") {
        const request = new Service();
        const resultBuscaCep = await request.buscaViaCep(cep);
        if (!resultBuscaCep.erro) {
          this.form.enderecoObj.referencia = resultBuscaCep.complemento;
          this.form.enderecoObj.rua = resultBuscaCep.logradouro;
          this.form.enderecoObj.bairro =
            resultBuscaCep.bairro === "" ? "Centro" : resultBuscaCep.bairro;

          if (
            resultBuscaCep.uf != (this.form.estado ? this.form.estado.uf : "")
          ) {
            const newEstado = await this.getEstadoByUf(resultBuscaCep.uf);
            if (newEstado && newEstado != null) this.form.estado = newEstado;
            this.getCidades(this.form.estado);
            const newCidade = await this.getCidadeByUfAndName(
              resultBuscaCep.uf,
              resultBuscaCep.localidade
            );
            if (newCidade && newCidade != null) this.form.cidadeObj = newCidade;
          } else {
            const newCidade = await this.getCidadeByUfAndName(
              resultBuscaCep.uf,
              resultBuscaCep.localidade
            );
            if (newCidade && newCidade != null) this.form.cidadeObj = newCidade;
          }
        }
      }
    },

    async getCidadeByUfAndName(uf = "", nome = "") {
      const request = new Service();
      return await request.getAll(
        { uf: uf, nome: nome },
        "cidades/findByNomeAndEstado"
      );
    },

    async getEstadoByUf(uf = "") {
      const request = new Service();
      return await request.getAll({ uf: uf }, "estados/findByUf");
    },

    cancel() {
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.page-section {
  padding: 3rem 0;
}
.check-termos {
  padding: 15px;
}
.termos {
  font-size: 13px;
  padding: 5px;
}
/* .wizard-tab-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */
@media (max-width: 350px) {
  .card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
